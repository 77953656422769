<template>
  <v-container id="login" fluid tag="section">
    <alert-notification :message="alert" />
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card color="primary">
          <template #heading>
            <div class="display-2 font-weight-light">
              {{ $t('login.title') }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="input.username" :label="$t('login.input.username')" />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="input.password" :type="showPassword ? 'text' : 'password'" :label="$t('login.input.password')"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="showPassword = !showPassword" @keyup.enter="login"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn :disabled="loading" :loading="loading" color="primary" class="mr-0" @click="login">
                    {{ $t('login.button') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import ability from '@/ability';

  export default {
    name: 'Login',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        input: {
          username: '',
          password: ''
        },
        loading: false,
        showPassword: false
      };
    },
    mounted () {
      this.$store.commit('SET_TOKEN', undefined);
      this.$store.commit('SET_USER', undefined);
      this.$store.commit('SET_LOCATION', undefined);
    },
    methods: {
      async login () {
        if (this.loading) {
          return false;
        }

        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'auth', { username: this.input.username, password: this.input.password });
          if (response?.data?.token !== undefined && response?.data?.user !== undefined) {
            const token = response.data.token;
            const user = response.data.user;
            this.$ability.update(ability(user).rules);
            this.$store.commit('SET_TOKEN', token);
            this.$store.commit('SET_USER', user);
            this.$store.commit('SET_LOCATION', {
              location: user.location,
              locations: user.locations
            });

            await this.$router.push({ name: 'home' });
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('login.error'));
        }
        this.loading = false;
      }
    }
  };
</script>
